@mixin __FF_SAME_ORIGIN_GRAPHQL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ADD_TO_CART_DRAWER__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_BOTIFY_IN_HEAD__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_BOTIFY_ASYNC__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CHECK_INVENTORY_VEHICLEINFO__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CHECK_WIPER_AVAILABILITY_FOR_STORE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_GEOPOINT_DIRECTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_GK_EMPLOYEE__DISCOUNT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_GK_ALLOW_OPTIONAL_AND_PARTIAL_FITMENT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_INSTANT_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_IS_APPOINTMENT_STANDARD_QUOTE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_KEYWORD_REDIRECT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_NO_STORE_IN_AREA_DRAWER__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_LICENSE_LOOKUP_FIELDS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_CART_ACCOUNT_DRAWER__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_MY_ACCOUNT_($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_PERSIST_MULTISTORE_PLP_PRODUCT_TO_PDP__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_PDP_REDESIGN__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_TREADWELL_DATA_NONTREADWELL_PLP__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_OPTIONAL_FITMENT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_CONFIRMATION_SURVEY_INTEGRATION__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PARTYTOWN__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_360_WHEEL_VIEW__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_NEARBY_STORE_STOCK_STANDARD__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_NEARBY_STORE_STOCK_STAGGERED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_NEARBY_STORE_STOCK_PREBUILT_PACKAGES__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_NEARBY_STORE_STOCK_CANONICAL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PREFETCH_APPOINTMENTS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PRODUCT_COMPARE_WITH_CONTEXT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_PRODUCT_IMAGE_FORMATS_AUTO_SYNC__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PRODUCT_RECONCILIATION_CALL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_RENAME_TREADWELL_CONTENT_QUERY_($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SALESFORCE_CHAT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_SERVICE_APPOINTMENT_REDESIGN__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_SESSION_TIMEOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SOURCE_DEFENSE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SHOP_BY_BRAND_DYNAMIC__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_STORE_IN_DIFFERENT_STATE_DRAWER__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TIRES_THAT_DONT_FIT_MESSAGING__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TREADWELL_LANDING_PAGE_CMS__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_UTAG_SYNC__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WHEEL_VISUALIZER_REDESIGN__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_AFFIRM_PAY_CHECKOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_APPLE_PAY_CHECKOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_GOOGLE_PAY_CHECKOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PAYPAL_CHECKOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
/* stylelint-disable declaration-no-important */
@import '../../../shared/scss/dt-base.scss';

%option {
  padding: 12px 10px;
  background: transparent;
  color: $grey-800;

  @include media($min-sm) {
    padding: 4px;
  }

  @include media($min-md) {
    padding: 5px;
  }

  @include media($min-lg) {
    padding: 12px 11px;
  }
}

%popover {
  top: 52px !important;
  border: 1px solid $medium-light-gray;
  :global {
    .popover__pointer {
      border: 1px solid $medium-light-gray;
      background: $white;
    }

    .popover__child-wrapper {
      background: $white;
    }
  }

  @include media($min-sm) {
    display: block;
  }
}

.wrapper {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: $white;
  z-index: 99;
  border-bottom: 1px solid $grey-200;

  @include media($min-sm) {
    z-index: 10;
  }
}

.container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 105px;
  margin: 0 auto;
  max-width: 1720px;

  @include media($min-sm) {
    height: 112px;
    padding: 0 20px;
  }

  @include media($min-md) {
    padding: 0 40px;
  }

  @include media($min-xl) {
    padding: 0 60px;
    position: relative;
  }
}

.mini-cart-popover {
  @extend %popover;

  width: 374px;
  max-height: 628px;
  min-height: 425px;
}

.mini-cart-empty-popover {
  @extend %popover;

  width: 374px;
  height: 341px;
}

.my-store-popover {
  @extend %popover;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 550px;
  height: auto;
}

.need-help-popover {
  top: 52px !important;
  border: 1px solid $grey-b-500;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 177px;
  height: 112px;

  @include media($min-sm) {
    display: block;
  }

  :global {
    .cec-contact-us {
      color: $white;
      padding: 12px 16px;
      background: $grey-b-500;
      align-items: flex-start;
      border-radius: 4px;
    }

    .cec-contact-us__top-section {
      flex-direction: column;
    }

    .cec-contact-us__bottom-section {
      flex-direction: column;
      width: 100%;
      border-top: 1px solid $grey-200;
      margin-top: 4px;
      padding-top: 4px;

      .hours {
        margin-right: 0;
      }

      > div {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      span {
        margin-right: 0;
      }
    }

    .popover__pointer {
      border: 1px solid $grey-b-500;
      background: $grey-b-500;
    }
  }
}

.site-logo {
  display: block;
  flex-shrink: 0;
  width: 75px;

  @include media($min-sm) {
    width: 150px;
    padding-right: 23px;
  }

  @include media($min-md) {
    width: 181px;
    padding-right: 43px;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.nav {
  flex: 1 0;
  height: 100%;
}

.nav-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  min-height: 65px;
  border-bottom: 0.5px solid $grey-500;

  &:last-child {
    border: 0;
    max-height: 50px;
    min-height: 40px;
  }

  @include media($min-sm) {
    padding: 0;
    height: 50%;
    min-height: 40px;
  }
}

.nav-links {
  display: flex;
  align-items: center;

  .nav-link {
    @extend %option;
    text-transform: uppercase;

    &:global(.nav-link-deals) {
      color: $green-700;
    }
    .arrow-icon {
      color: $grey-800;
      margin-left: 4px;
      font-size: rem(17);
      vertical-align: -4px;
    }
  }
}

.menu-button {
  @extend %option;
  text-transform: uppercase;
  margin: 4px 0;
}

.menu-icon {
  margin-right: 5px;
  font-size: rem(13);
}

.arrow-icon {
  margin-left: 12px;
  color: $grey-600;
  pointer-events: none;
}

.marker-icon {
  color: $dt-red;
  font-size: rem(14);
  margin-right: 4px;
}

svg.user-icon {
  font-size: rem(18);
  color: $blue-500;
}

.nav-buttons {
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  width: 82px;
  margin-left: -10px;
  padding-right: 10px;

  @include media($min-sm) {
    padding-right: 0;
  }

  > button::before {
    @include media($min-sm) {
      content: '';
      position: absolute;
      left: 0px;
      height: 26px;
      width: 1px;
      background: $grey-200;
    }
  }

  .myaccount-link {
    color: $blue-700;

    &:hover {
      color: $blue-500;
    }

    @include media($min-sm) {
      padding-right: 6px;
    }

    @include media($min-lg) {
      padding: 12px;
    }
  }
  .myaccount-link::before {
    display: none;
  }
  .myaccount-link::after {
    content: '';
    position: absolute;
    right: 0px;
    height: 18px;
    width: 1px;
    background: $grey-200;

    @include media($min-sm) {
      height: 26px;
    }
  }
}

.nav-button {
  display: flex;
  align-items: center;
  position: relative;
  @extend %option;
  white-space: nowrap;

  @include media($min-sm) {
    text-transform: none;

    .arrow-icon {
      margin-left: 8px;
    }
  }

  .arrow-icon {
    margin-left: 5px;
    font-size: rem(22);
  }

  .search-placeholder {
    margin-right: 15px;
    color: $grey-600;
  }

  :global .header__icon {
    color: $grey-700;
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  & > * {
    pointer-events: none;
  }
}

.vehicle-select {
  color: $dt-blue;
  column-gap: 8px;

  &:hover {
    color: $blue-500;
  }

  .car-icon {
    font-size: rem(17);
    color: $grey-800;
  }

  @include media($min-sm) {
    padding: 5px 10px;
    max-width: 115px;
  }

  @include media($min-md) {
    max-width: inherit;
  }

  @include media($min-lg) {
    padding: 12px;
    column-gap: 12px;
  }
}

.nav-button.vehicle-select:visited {
  color: inherit;
}

.nav-notification {
  position: absolute;
  top: 13px;
  margin-left: 14px;

  @include media($min-sm) {
    position: static;
    margin-left: 6px;
  }
}

.sign-in {
  flex-shrink: 0;
  margin-left: 7px;
}

.feature-label {
  flex-shrink: 0;
}

.my-store-address {
  max-width: 21vw;
  color: $grey-600;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @include media($min-md) {
    max-width: 27vw;
  }
}

.my-vehicle {
  max-width: 12vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $grey-800;

  @include media($min-md) {
    max-width: 17vw;
  }
}

.skip {
  position: absolute;
  top: -10000px;
}

.user-popover {
  @extend %popover;
  width: 187px;
  :global .popover__pointer {
    background: $dt-light-medium-gray;

    @include media($min-sm) {
      background: $white;
    }
  }
}

.greeting {
  color: $grey-800;

  @include media($min-sm) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 70px;
  }

  @include media($min-md) {
    max-width: 400px;
  }
}

.user-options {
  width: 185px;

  li {
    margin: 0 10px;
    padding: 16px 5px;
    text-align: center;
    border-bottom: 1px solid $light-gray;
  }

  .mobile-greeting {
    background: $dt-light-medium-gray;
    margin: 0;
  }
}

.dropdown-menu {
  display: flex;
}

.mini-store {
  max-width: 216px;

  @include media($min-sm) {
    max-width: 100%;
  }
}

.cart-button {
  @include media($min-sm) {
    padding-left: 13px;
  }

  @include media($min-md) {
    padding: 15px 12px;
  }
}

.leftside-nav {
  display: flex;
}

.need-help-button.need-help-button {
  background-color: $grey-50;
  font-size: rem(14);
  line-height: 20px;
  height: min-content;
  align-self: center;
  width: 148px;
  padding: 6px 9px;
  margin-left: 10px;
  color: $blue-700;
  border-radius: 4px;
  position: relative;

  .phone-icon {
    color: $blue-700;
    font-size: rem(14);
    position: absolute;
    left: 15px;
    top: 10px;
  }

  .arrow-icon {
    color: $blue-700;
    margin: 0;
    font-size: 16px;
    position: absolute;
    right: 15px;
    top: 10px;
  }
}

.need-help-banner {
  padding: 6px 0;
  border-top: 1px solid $grey-30;
  text-align: center;
  background: $grey-50;
  display: flex;
  justify-content: center;
}

.cec-phone {
  font-size: rem(14);
  font-weight: $normal;
  margin-left: 10px;
}

.location-failed-warning {
  margin: 12px 12px 0px 12px;

  :global {
    .location-failed-message {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
